import React from "react"
import { useState } from "react"
import {PlusCircleIcon, CakeIcon} from "@heroicons/react/24/solid";
import { useAuth } from "../../util/auth";
import { updateLiveLink } from "../../util/db";
import { Timestamp } from "firebase/firestore";

export default function HostZoom() {
    const auth = useAuth()
    const currentDate = new Date();
    const [isOpen, setisOpen] = useState(false)
    const [buttonDisabled, setButtonDisabled] = useState(false)
    const [link, setLink] = useState("")
    const [time, setTime] = useState("")
    const [start, setStart] = useState("")
    const [rest, setRest] = useState([])
    const [successfullPost, setSuccessfullPost] = useState(false)

    function getTimestampFromTimeString(timeString) {
        // Step 1: Get the current date
        const currentDate = new Date();
    
        // Step 2: Extract hours and minutes from the timeString (format "XX:XX")
        const [hours, minutes] = timeString.split(':').map(Number);
    
        // Step 3: Set the hours and minutes to the current date
        currentDate.setHours(hours);
        currentDate.setMinutes(minutes);
        currentDate.setSeconds(0);  // Optional: Reset seconds
        currentDate.setMilliseconds(0);  // Optional: Reset milliseconds
    
        // Step 4: Return the Date object or Firestore Timestamp
        return Timestamp.fromDate(currentDate);  // Firestore Timestamp
        // return currentDate;  // If you only need a Date object
    }

    function closePopup() {
        setisOpen(false)
        setSuccessfullPost(false)
        setButtonDisabled(false)
      }
    async function updateDatabase() {
        setButtonDisabled(true)
        await updateLiveLink("73SgQwFutFWzXUOdyogw", {
            link: link,
            startTime: getTimestampFromTimeString(start),
            requiredPlans: rest,
            finishTime: getTimestampFromTimeString(time),
        })
        setSuccessfullPost(true)
        setButtonDisabled(false)
    }
    return (
        <>
        {(isOpen)&&(
                <div className="z-20 fixed inset-0 bg-black bg-opacity-75 flex justify-center items-center">
                  {/* Popup content */}
                  <div className="bg-white p-8 rounded-lg shadow-lg lg:w-1/2 w-3/4 max-w-4xl relative">
                    {successfullPost===true?(
                    <div className="flex flex-col text-center space-y-4">
                    <CakeIcon className='h-12 text-green-600'/>
                    <h2 className='text-5xl font-semibold'>Users can Now Access The Zoom call From Their Dashboard!</h2>
                    <p className='text-lg font-semithin'>Woohoo! The users will be able to view the notification that we are now live until "Time."</p>
                    <button onClick={()=> closePopup()} className=" absolute top-0 right-4 text-3xl text-gray-400 rounded-md">
                    &times;
                  </button>
                  </div>
                  ):(
                    <>
                    <h2 className=" text-center text-5xl font-bold pb-6">Go Live</h2>
                    <p className='text-gray-600 text-center'>Please enter the relevant information before going live.</p>
                    <button onClick={()=> closePopup()} className=" absolute top-0 right-4 text-3xl text-gray-400 rounded-md">
                      &times;
                    </button>

                    <div className='h-0 my-4 border-t-4 border-dotted border-gray-200'></div>
                    <p className='text-gray-400 text-left m-2'>Zoom Link</p>
                    <div className='mb-4'>
                        <label htmlFor="email" className="sr-only">
                            Zoom Link
                        </label>
                        <input
                            type="text"
                            name="link"
                            id="link"
                            className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                            placeholder='https://us.zoom.us/1234'
                            value={link}
                            onChange={(e) => setLink(e.target.value)}
                        />
                    </div>
                    <p className='text-gray-400 text-left m-2'>{`Start Time (${currentDate.toISOString().split("T")[0]})`}</p>
                    <div className='mb-4'>
                        <input
                            type="time"
                            name="time-start"
                            id="time-start"
                            className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                            placeholder='https://us.zoom.us/1234'
                            value={start}
                            onChange={(e) => setStart(e.target.value)}
                        />
                    </div>
                    <p className='text-gray-400 text-left m-2'>{`Finish Time (${currentDate.toISOString().split("T")[0]})`}</p>
                    <div className='mb-4'>
                        <label htmlFor="email" className="sr-only">
                            Finish Time
                        </label>
                        <input
                            type="time"
                            name="time"
                            id="time"
                            className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                            placeholder='https://us.zoom.us/1234'
                            value={time}
                            onChange={(e) => setTime(e.target.value)}
                        />
                    </div>

                    <p className='text-gray-400 text-left m-2'>Select a Permission Level</p>
            <span className="isolate lg:inline-flex rounded-md mb-4">
                <button
                    onClick={()=>{setRest([])}}
                    type="button"
                    className={`relative inline-flex items-center rounded-full ${rest.length===0?"bg-green-100 text-green-600 ring-green-600":"bg-white text-gray-400 ring-gray-300"} px-3 py-2 mr-2 text-sm  ring-1 ring-inset  hover:bg-gray-50 focus:z-10`}
                >
                    Anyone
                </button>
                <button
                    onClick={()=>{setRest(["sby", "sbm","tp", "tpl", "proc", "life"])}}
                    type="button"
                    className={`relative inline-flex items-center rounded-full ${rest.length===6?"bg-green-100 text-green-600 ring-green-600":"bg-white text-gray-400 ring-gray-300"} px-3 py-2 mr-2 text-sm  ring-1 ring-inset  hover:bg-gray-50 focus:z-10`}
                >
                    Stairs and Buckets
                </button>
                <button
                    onClick={()=>{setRest(["tp","tpl", "proc", "life"])}}
                    type="button"
                    className={`relative inline-flex items-center rounded-full ${rest.length===4?"bg-green-100 text-green-600 ring-green-600":"bg-white text-gray-400 ring-gray-300"} px-3 py-2 mr-2 text-sm  ring-1 ring-inset  hover:bg-gray-50 focus:z-10`}
                >
                    Sales Processes
                </button>
                <button
                    onClick={()=>{setRest(["tp","tpl", "life"])}}
                    type="button"
                    className={`relative inline-flex items-center rounded-full ${rest.length===3?"bg-green-100 text-green-600 ring-green-600":"bg-white text-gray-400 ring-gray-300"} px-3 py-2 mr-2 text-sm  ring-1 ring-inset  hover:bg-gray-50 focus:z-10`}
                >
                    Agency Training
                </button>
                <button
                    onClick={()=>{setRest(["life"])}}
                    type="button"
                    className={`relative inline-flex items-center rounded-full ${rest.length===1?"bg-green-100 text-green-600 ring-green-600":"bg-white text-gray-400 ring-gray-300"} px-3 py-2 mr-2 text-sm  ring-1 ring-inset  hover:bg-gray-50 focus:z-10`}
                >
                    Lifetime Membership
                </button>

            </span>

                    <button onClick={()=>updateDatabase()} disabled={buttonDisabled} className="flex flex-row bg-green-600 text-gray-200 py-2 px-4 w-full justify-center rounded-md focus:outline-none focus:border-green-700">Go Live</button>
                    </>)}
        
                  </div>
                </div>
        )}
        <button
            type="button"
            onClick={() => {setisOpen(true)}}
            className="relative flex flex-col items-center block min-w-[280px] rounded-lg border-2 border-dashed border-green-700 p-12 text-center hover:border-green-500 focus:outline-none text-green-700 hover:text-green-500 hover:bg-green-50"
        >
            <PlusCircleIcon className="h-12 justify-center "/>
            <span className="mt-2 block text-sm font-semibold text-gray-900">Go Live</span>
        </button>
        </>

    )
  }