import React from "react"
import { useAuth } from "../../util/auth";
import { useState } from "react";
import {  XMarkIcon } from '@heroicons/react/20/solid'
import { useLiveLink } from "../../util/db";
import { Link } from "../../util/router";
import ValidatePaymentWrapper from "./ValidatePaymentWrapper";

export default function LiveViewSection() {
    const auth = useAuth()
    const [show, setShow] = useState(true)
    const currentDate = new Date();
    const utcSeconds = Math.floor(currentDate.getTime()/1000);

    function timestampToMountainTime(timestamp) {
        // Create a Date object from the timestamp (convert seconds to milliseconds)
        const date = new Date(timestamp * 1000);
      
        // Define options for formatting, specifying the Mountain Time Zone
        const options = {
          hour: '2-digit',
          minute: '2-digit',
          second: '2-digit',
          timeZone: 'America/Denver', // Mountain Time Zone
          hour12: false, // 24-hour format; set to true for 12-hour format
        };
      
        // Format the date using Intl.DateTimeFormat
        const timeOfDay = new Intl.DateTimeFormat('en-US', options).format(date);
        return timeOfDay;
      }
      
      // Example usage:
      const timestamp = 1633072800; // Example timestamp
      
      function isSameDayOfWeek(timestamp) {
        // Create a Date object from the timestamp (convert seconds to milliseconds)
        const date = new Date(timestamp * 1000);
      
        // Get the day of the week from the timestamp date (0 = Sunday, 6 = Saturday)
        const dayOfWeek = date.getDay();
      
        // Get the current day of the week
        const currentDate = new Date();
        const currentDayOfWeek = currentDate.getDay();
      
        // Compare and return true if they are the same
        return dayOfWeek === currentDayOfWeek;
      }
      

    const {
        data: link,
        error: linkError,
        status: linkStatus
    } = useLiveLink("73SgQwFutFWzXUOdyogw")


    return (
        <>
        {(linkStatus === "success" && link)&&(
            <>
    {show&& (link.finishTime.seconds > utcSeconds && isSameDayOfWeek(link.startTime.seconds)) &&
    <ValidatePaymentWrapper requiredPlans={link.requiredPlans}>
    <div className=" bg-green-50 py-4 px-12 rounded-lg">
      <div className="flex">
        <div className="h-6 bg-red"/>
        <div className="ml-12">
          <p className="text-2xl font-medium text-green-600">We are Live Today!</p>
        </div>
        <div className="ml-auto pl-3">
          <div className="-mx-1.5 -my-1.5">
            <button
              type="button"
              onClick={( ) => setShow(false)}
              className="inline-flex rounded-md bg-green-50 p-1.5 text-green-500 hover:bg-green-100 focus:outline-none focus:ring-2 focus:ring-green-600 focus:ring-offset-2 focus:ring-offset-green-50"
            >
              <span className="sr-only">Dismiss</span>
              <XMarkIcon aria-hidden="true" className="h-5 w-5" />
            </button>
          </div>
        </div>
      </div>
      <p className="text-md font-medium text-gray-600">We will be going live today on Zoom! The meeting is scheduled for {timestampToMountainTime(link.startTime.seconds)} Mountain Time.  Click <strong><a href={link.link}>here</a></strong> to catch us live!</p>
      <a href={link.link}>
      <button className="bg-green-600 text-white m-2 p-2 rounded-lg">Watch Now</button>
      </a>
    </div>
    </ValidatePaymentWrapper>

    }
    </>        )}
        </>

    )
  }