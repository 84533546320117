import React from "react"
import { useState } from "react"
import {CakeIcon} from "@heroicons/react/24/solid";
import { createInvitation, useUserByEmail } from "../../util/db";
import { useAuth } from "../../util/auth";
import LoadingIcon from "../LoadingIcon";
import { apiRequest } from "../../util/util";


export default function MemberAddModal({email, team}) {
    const auth = useAuth()
    const [buttonDisabled, setButtonDisabled] = useState(false)
    const [success, setSuccess] = useState(false)
    const [successExt, setSuccessExt] = useState(false)

    const defaultAvatar = 'https://via.placeholder.com/150?text=Avatar';


    const {
        data: user,
        status: userStatus,
        error: userError
    } = useUserByEmail(email)

    async function sendInvitation() {
        setButtonDisabled(true)

        await createInvitation(
            {
                owner: user[0].id,
                fromEmail: auth.user.email,
                teamName: team.name,
                teamId: team.id,
                sender: auth.user.uid
            }
        )
        setSuccess(true)
        setButtonDisabled(false)
    }


    async function sendExternalInvitation() {
        setButtonDisabled(true)

        await createInvitation(
            {
                owner: email,
                fromEmail: auth.user.email,
                teamName: team.name,
                teamId: team.id,
                secure: false,
                sender: auth.user.uid
            }
        )
        await sendEmail()
        setSuccessExt(true)
        setButtonDisabled(false)
    }

    const sendEmail = async () =>  {
        setButtonDisabled(true)
        var message = `Hello, you have been invited to join a team on Insurance Agency Training. Please visit https://insuranceagencytraining.org/auth/signup to create an account. Please ensure you create an account with the email that this was sent to to ensure you receive the invitation. Once you have created an account click the bell in the top right to accept the request!`
        try {
          const data = {
                message: message,
                email: email,
                method: "Email",
                subject: "You Just Got Invited!"
            };
    
            const response = await apiRequest('send-message', 'POST', data);
            console.log('Response after sending code:', response);
        } catch (error) {
          console.error("Error creating code: ", error)
        }
        setButtonDisabled(false)
      }


    return (
        <>
        {(userStatus === "success" && user && user.length === 1)&&(
        <div className="flex flex-col items-center">
            {(!success)&&(
            <>
            {!(team.members.includes(user[0].id))
                ?<>
                <h2 className=" text-center text-3xl font-bold pb-6">Here is who we found: </h2>
                <div className='flex flex-row align-center bg-green-50 p-4 rounded-lg border-green-700 border'>
                <img src={user[0].profilePhoto?user[0].profilePhoto:defaultAvatar} alt={user[0].name} className="h-16 w-16 rounded-md object-cover mr-4" />
                    <div>
                    <div className="text-lg font-semibold mt-2">{user[0].name}</div>
                    <div className="text-sm text-gray-500">{user[0].email}</div>
                    </div>
                </div>
                <p className="py-2 text-md text-center text-gray-400">If this looks correct feel free to send them an invitation to join your team! If not, try searching for the email again. Remember, the user must have an account with us already.</p>
                {(buttonDisabled)&&<LoadingIcon className="w-6"/>}
                <button onClick={()=> sendInvitation()} disabled={buttonDisabled} className="flex flex-row bg-green-600 text-gray-200 py-2 px-4 w-full justify-center rounded-md focus:outline-none focus:border-green-700">Send Invitation</button>
                </>
                :
                <>
                <h2 className=" text-center text-3xl font-bold pb-6">Hmm... They are already in this team.</h2>
                <p className="pb-4 text-md text-center text-gray-400">It appears that this user is already a member of this team. Try again with a different email.</p>
                </>
                }
            </>
            )}

        </div>
        )}

        {(userStatus === "success" && user.length===0 && !successExt) &&(
            <div className="flex flex-col items-center">
            <h2 className=" text-center text-3xl font-bold pb-6">Hmm?</h2>
            <p className="pb-4 text-md text-center text-gray-500">We couldn't find the user "<strong className="text-gray-600">{email}</strong>" in our system. If you believe that this user already has an account with us please verify that the entered email is correct.</p>
            <p className="pb-4 text-md text-center text-gray-500">If you want to invite the user to the platform, feel free!</p>
            {/* <button disabled={true} onClick={()=> sendExternalInvitation()} className="flex flex-row bg-green-600 text-gray-200 py-2 px-4 w-full justify-center rounded-md focus:outline-none focus:border-green-700">Invite to Platform</button> */}

            <button onClick={()=> sendExternalInvitation()} disabled={buttonDisabled} className="flex flex-row bg-green-600 text-gray-200 py-2 px-4 w-full justify-center rounded-md focus:outline-none focus:border-green-700">Invite to Platform</button>
            </div>
        )}

        {(success)&&(

        <div className="flex flex-col text-center space-y-4">
        <CakeIcon className='h-12 text-green-600'/>
        <h2 className='text-5xl font-semibold'>Invitation Sent Successfully!</h2>
        <p className='text-lg font-semithin'>Once they accept the invitation, they will show up in your "Teams" section.</p>
        </div>
        )}

        {(successExt)&&(
        <div className="flex flex-col text-center space-y-4">
        <CakeIcon className='h-12 text-green-600'/>
        <h2 className='text-5xl font-semibold'>Invitation Sent Successfully!</h2>
        <p className='text-lg font-semithin'>An email has been sent inviting the user to the platform. Once they have signed in, they will see the invitation and can join your team!</p>
        </div>
        )}
        </>

    )
  }