import { useState } from "react"
import { handleZapier } from "../../util/zapier"
import { useRouter } from "../../util/router"
import img from "../../assets/sbgraphic.jpeg"

export default function LeadForm(props) {

    const router = useRouter()

    const [checked, setChecked] = useState(false)

    const [first, setFirst] = useState("")
    const [last, setLast] = useState("")
    const [email, setEmail] = useState("")
    const [cell, setCell] = useState("")

        // useEffect(() => {
        //   // Create a new script element
        //   const script = document.createElement('script');
        //   script.src = "https://link.msgsndr.com/js/form_embed.js";
        //   script.async = true; // Ensure script loads asynchronously
          
        //   // Append the script to the body
        //   document.body.appendChild(script);
      
        //   // Cleanup function to remove the script when the component unmounts
        //   return () => {
        //     document.body.removeChild(script);
        //   };
        // }, []); // Empty dependency array to run the effect only once


  async function postZap(email, cell, first, last, tags, afterUrl) {
    const resp =  await  handleZapier("createLead", 
      {
        "email": email,
        "phone":cell,
        "firstName": first,
        "lastName":last,
        "tags": tags
      })
        router.push(afterUrl)
     }

  return (
    <div>
        <div className="flex flex-row space-x-2 divide-x">
      <div className="space-y-6 w-1/3">
        <div className="border-b border-gray-900/10 pb-12">
          <h2 className="text-base font-semibold leading-7 text-gray-900">Personal Information</h2>
          <p className="mt-1 text-sm leading-6 text-gray-600">We just need a little bit of information first.</p>

          <div className="mt-4 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
            <div className="sm:col-span-3">
              <label htmlFor="first-name" className="block text-sm font-medium text-gray-900">
                First name
              </label>
              <div className="mt-1">
                <input
                  id="first-name"
                  name="first-name"
                  value={first}
                  onChange={(e) => setFirst(e.target.value)}
                  type="text"
                  autoComplete="given-name"
                  className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                />
              </div>
            </div>

            <div className="sm:col-span-3">
              <label htmlFor="last-name" className="block text-sm font-medium text-gray-900">
                Last name
              </label>
              <div className="mt-1">
                <input
                  id="last-name"
                  value={last}
                  onChange={(e) => setLast(e.target.value)}
                  name="last-name"
                  type="text"
                  autoComplete="family-name"
                  className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                />
              </div>
            </div>

            <div className="sm:col-span-4">
              <label htmlFor="email" className="block text-sm font-medium text-gray-900">
                Email address
              </label>
              <div className="mt-1">
                <input
                  id="email"
                  name="email"
                  onChange={(e) => setEmail(e.target.value)}
                  value={email}
                  type="email"
                  autoComplete="email"
                  className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                />
              </div>
            </div>

            <div className="col-span-full">
              <label htmlFor="phone" className="block text-sm font-medium text-gray-900">
                Cell Phone Number
              </label>
              <div className="mt-1">
                <input
                  id="phone"
                  name="phone"
                  value={cell}
                  onChange={(e) => {
                    const value = e.target.value.replace(/\D/g, ''); // Remove any non-numeric characters
                    setCell(parseInt(value));
                  }} 
                  type="tel"
                  autoComplete="tel"
                  className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                />
              </div>
            </div>
          </div>
        </div>

        <div className="border-b border-gray-900/10 pb-6">
          <label>
                <input
                  type="checkbox"
                  checked={checked}
                  onChange={() => setChecked(!checked)}
                />
                <span style={{ marginLeft: "8px" }} className='text-xs text-gray-400'>
                By submitting this form you agree to receive personalized marketing communications in the form of email or SMS. You also agree to the collection of the information provided above.
                </span>
              </label>

        </div>
      </div>

      <div className="w-2/3 p-2">
      <div className="flex flex-row">
        <div>
        <h2 className="text-base font-semibold leading-7 text-green-800">Get Your Free "Stairs & Buckets" Ebook Today!</h2>
      <p className="text-sm leading-6 text-gray-600"><strong>Want to make your insurance sales more effective and easier for clients to understand?</strong> We're offering a free ebook that will show you how to use the "Stairs & Buckets" system—a straightforward and proven approach to explaining term and permanent life insurance. This system helps you build trust with clients, close more sales, and create a consistent, repeatable sales process.</p>
      <br/>
      <h2 className="text-base font-semibold leading-7 text-green-800">Why Download This Free Ebook?</h2>
        <li className="text-sm leading-6 text-gray-600">Learn how to clearly explain both term and permanent life insurance so your clients understand the value of each type.</li>
        <li className="text-sm leading-6 text-gray-600">Discover proven strategies to communicate the benefits of life insurance effectively.</li>
        <li className="text-sm leading-6 text-gray-600">Use the "Stairs & Buckets" system to make your sales process impactful, easy to repeat, and effective for your entire team.</li><br/>
   
        </div>
        <img src={img} className="h-96 lg:block hidden"></img>
      </div>
 <p className="text-sm leading-6 text-gray-600">The "Stairs & Buckets" system is the key to making insurance sales simpler and more successful—not just for you, but for your whole team. This free ebook will guide you step-by-step on how to use this approach to transform your sales.</p>
    <p className="text-sm leading-6 text-gray-600"><strong>Get your free ebook now and start transforming your sales process with the "Stairs & Buckets" system!</strong></p>
      </div>
        </div>
      <div className="mt-6 flex items-center justify-center gap-x-6">
        <button
          disabled={!checked || (cell === "" || first==="" || last==="" || email==="")}
          onClick={() => postZap(email,cell,first,last,props.zapTags, props.afterUrl)}
          className="rounded-md bg-green-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-green-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-green-600"
        >
          Submit
        </button>
      </div>
    </div>
  )
}


{/* <iframe
src="https://api.leadconnectorhq.com/widget/form/Y24tPj8537X82S8TFT96"
style={{ width: "100%", height: "100%", border: "none", borderRadius: "3px", boxShadow: "none" }}
id="inline-Y24tPj8537X82S8TFT96" 
data-layout="{'id':'INLINE'}"
data-trigger-type="alwaysShow"
data-trigger-value=""
data-activation-type="alwaysActivated"
data-activation-value=""
data-deactivation-type="neverDeactivate"
data-deactivation-value=""
data-form-name="Form 1"
data-height="664"
data-layout-iframe-id="inline-Y24tPj8537X82S8TFT96"
data-form-id="Y24tPj8537X82S8TFT96"
title="Form 1"
    >
</iframe> */}