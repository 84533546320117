import React, { useState } from "react";
import Section from "./Section";
import SectionHeader from "./SectionHeader";
import { RadioGroup } from '@headlessui/react'
import { useAuth } from "./../util/auth";
import PlanCard from "./Custom/PlanCard";
import { Link, useRouter } from "../util/router";
import { QuestionMarkCircleIcon, ChevronUpIcon, ChevronDownIcon } from "@heroicons/react/24/outline";
import FullBanner from "./Custom/FullBanner";

const singleIds = ["sby", "sbm", "proc", "tp", "life"]

// const memoryOptions = [
//   {id: "plans", name: "Purchase Plans", green: ["plans"]},
//   {id: "ind", name: "Individual Pricing", green: ["ind"]}
//   ]

const plans = [
  {
    id: "sby",
    name: "Stairs and Buckets",
    price: "599.99",
    period: "per year",
    perks: [
      <>
        <strong>5&nbsp;</strong>Seats at your agency
      </>,
      <>
        <strong>Unlimited&nbsp;</strong> Video Sends
      </>,
      <>
       Team <strong>&nbsp;and&nbsp;</strong> Individual Analytics
      </>,
      <>
        <strong>4&nbsp;</strong> Different Languages
      </>,
      <>
      <strong>Free&nbsp;</strong> Training Webinar
    </>,
    ],
  },
  {
    id: "sbm",
    name: "Stairs and Buckets",
    price: "59.99",
    period: "per month",
    perks: [
      <>
        <strong>5&nbsp;</strong>Seats at your agency
      </>,
      <>
        <strong>Unlimited&nbsp;</strong> Video Sends
      </>,
      <>
       Team <strong>&nbsp;and&nbsp;</strong> Individual Analytics
      </>,
      <>
        <strong>4&nbsp;</strong> Different Languages
      </>,
      <>
      <strong>Free&nbsp;</strong> Training Webinar
    </>,
    ],
  },
  {
    id: "proc",
    name: "Sales Processes",
    price: "1,999.99",
    items: ["sby", "sbm", "tp", "tp1", "tp2", "tp3", "tp4", "proc1", "proc2", "proc3", "proc4", "proc5", "proc6", "proc7", "shuff"],

    period: "per year",
    perks: [
      <>
        <strong>5&nbsp;</strong>Seats at your agency
      </>,
      <>
        <strong>6&nbsp;</strong>Webinars
      </>,
      <>
        <strong>Proven&nbsp;</strong> Scripts
      </>,
      <>
        <strong>Team&nbsp;</strong> Progress Tracking
      </>,
      <>
      <strong>Medicare&nbsp;</strong> Powerpoint Presentation
      </>,
      <>
      <strong>Large Premium&nbsp;</strong> Life Sales Strategies
    </>,
    ],
  },
  {
    id: "tp",
    name: "Agency Training",
    price: "3,999.99",
    items: ["sby", "sbm", "tp1", "tp2", "tp3", "tp4"],
    period: "per year",
    perks: [
      <>
        <strong>5&nbsp;</strong>Seats at your agency
      </>,
      <>
        <strong>$3,000&nbsp;</strong> Non-Compete
      </>,
      <>
       Staff Handbook
      </>,
      <>
        <strong>Million Dollar&nbsp;</strong> Manual
      </>,
      <>
        <strong>Included&nbsp;</strong>Sales Processes
      </>,
    ],
  },
  {
    id: "life",
    name: "Million-Dollar Membership",
    price: "14,999.99",
    period:"one time",
    perks: [
      <>
        <strong>One&nbsp;</strong> Payment
      </>,
      <>
        <strong>Annual&nbsp;</strong> Rocky Mountain Retreat
      </>,
      <>
        <strong>All &nbsp;</strong> Books
      </>,
      <>
      <strong>All&nbsp;</strong> Training Tools 
    </>,
    <>
    <strong>All&nbsp;</strong> Webinars 
    </>,
    <>
    <strong>All&nbsp;</strong> additional future content 
    </>,
      <>
        <strong>Exclusive&nbsp;</strong> Offers and Events
      </>,
    ],
  },
];


const items = [
  {id: "tp1", name: "Million Dollar Manual", plans: ["tp", "proc"], price: "699.99", period: "per year"},
  {id: "tp2", name: "Comprehensive Staff Handbook", plans: ["tp", "proc"], price: "299.99", period: "per year"},
  {id: "tp3", name: "Paychecks Never Lie", plans: ["tp", "proc"], price: "24.99", period: "one time"},
  {id: "tp4", name: "Bigger Better Paychecks", plans: ["tp", "proc"], price: "24.99", period: "one time"},
  
  {id: "proc1", name: "Sales Process One: Medicare", plans: ["proc"], price: "199.99", period: "per year"},
  {id: "proc2", name: "Sales Process Two: Premium Life", plans: ["proc"], price: "199.99", period: "per year"},
  {id: "proc3", name: "Sales Process Three: Finding the Money", plans: ["proc"], price: "199.99", period: "per year"},
  {id: "proc4", name: "Sales Process Four: Protect & Particpate", plans: ["proc"], price: "199.99", period: "per year"},
  {id: "proc5", name: "Sales Process Five: Understanding Money", plans: ["proc"], price: "199.99", period: "per year"},
  {id: "proc6", name: "Sales Process Six: Liability Wall", plans: ["proc"], price: "199.99", period: "per year"},
  {id: "proc7", name: "Medicare Slide", plans: ["proc"], price: "199.99", period: "per year"},
  {id: "shuff", name: "Service Webinars", plans: ["proc"], price: "199.99", period: "per year"},

]

const statuses = {
  Complete: 'text-green-700 bg-green-50 ring-green-600/20',
  'In progress': 'text-gray-600 bg-gray-50 ring-gray-500/10',
  Archived: 'text-yellow-800 bg-yellow-50 ring-yellow-600/20',
}

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

function arraysShareElement(arr1, arr2) {
  // Convert the first array to a Set for O(1) lookups
  const set1 = new Set(arr1);
  
  // Check if any element in the second array is in the Set
  for (const element of arr2) {
    if (set1.has(element)) {
      return true; // Return true if a common element is found
    }
  }
}

function PricingSection(props) {
  const router = useRouter()
  const auth = useAuth();
  const [selectedPlans, setSelectedPlans] = useState([])
  const [quantity, setQuantity] = useState(1)
  const [showMonth, setShowMonth] = useState(false)

  const appendItem = (planId) => {

    setSelectedPlans(prevPlans => {
      let updatedPlans;
  
      if (prevPlans.includes(planId)) {
        updatedPlans = prevPlans.filter(element => element !== planId);
      } else {
        if (planId === "life") {
          updatedPlans = ["life"];
        } else {
          updatedPlans = [...prevPlans, planId].filter(element => element!== "life");
        }
      }
      return updatedPlans;
    });
  };

  function calculateTotals(selectedPlans) {
    var one = 0
    var month = 0
    var year = 0
    var final = []
    selectedPlans.forEach(element => {
      if (plans.find(plan => plan.id === element)) {
         if (plans.find(plan =>  plan.id === element).period === "one time") {
          one += parseFloat(plans.find(plan => plan.id === element).price.replace(",", ""))
         } else if (plans.find(plan =>  plan.id === element).period === "per year") {
          year += parseFloat(plans.find(plan => plan.id === element).price.replace(",", ""))
         }else if (plans.find(plan =>  plan.id === element).period === "per month") {
          month += parseFloat(plans.find(plan => plan.id === element).price.replace(",", ""))
         }
      }
      if (items.find(item => item.id === element)) {
        if (items.find(plan =>  plan.id === element).period === "one time") {
          one += parseFloat(items.find(plan => plan.id === element).price.replace(",", ""))
         } else if (items.find(plan =>  plan.id === element).period === "per year") {
          year += parseFloat(items.find(plan => plan.id === element).price.replace(",", ""))
         }else if (items.find(plan =>  plan.id === element).period === "per month") {
          month += parseFloat(items.find(plan => plan.id === element).price.replace(",", ""))
         }
      }
    }
  );
  if (one>0) {
    final.push(`$${(one.toFixed(2)* Math.ceil(quantity/5)).toFixed(2)}`)
  }
  if (year>0) {
    final.push(`$${(year.toFixed(2)* Math.ceil(quantity/5)).toFixed(2)}/yr`)
  }
  if (month>0) {
    final.push(`$${(month.toFixed(2)* Math.ceil(quantity/5)).toFixed(2)}/mo`)
  }
return final
  }

  const appendPlan = (planId) => {

    setSelectedPlans(prevPlans => {
      let updatedPlans;
  
      if (prevPlans.includes(planId)) {
        updatedPlans = prevPlans.filter(element => element !== planId);
      } else {
        if (planId === "life") {
          updatedPlans = ["life"];
        } else {
          updatedPlans = [...prevPlans.filter(element => !singleIds.includes(element)), planId].filter(element => element!== "life");
          updatedPlans.forEach(each => {
            if (plans.find(plan => plan.id === planId).items?.includes(each)) {
            updatedPlans = updatedPlans.filter(element => element !== each)
            }
          })
        }
      }
      return updatedPlans;
    });
  };

  const handleChange = (e) => {
    const inputValue = e.target.value;
    if (/^\d*$/.test(inputValue) && inputValue>0) { // Only allow integer values
      setQuantity(inputValue);
    }
  };

  return (<>
  {/* {auth.user?.legacyIsActive&&<FullBanner title="Attention" text = "It seems that your account has subscriptions that were purchased on our old website. Please note that if you wish to manage your existing subscriptions, you can do that by filling out a form in settings."/>} */}
    <Section
      size={props.size}
      bgColor={props.bgColor}
      bgImage={props.bgImage}
      bgImageOpacity={props.bgImageOpacity}
      textColor={props.textColor}
    >
      <div className="container">
      {/* <div className="flex flex-row items-center justify-between mx-4 sm:w-auto w-full">
  <fieldset aria-label="Choose an option">
      <RadioGroup value={memoryOptions[0]} onChange={(e) => {
        router.push(e)
        setSelectedPlans([])
        }} className="mt-2 grid grid-cols-3 gap-3 sm:grid-cols-6 cursor-pointer">
        {memoryOptions.map((option) => (
          <RadioGroup.Option
            key={option.id}
            value={option.id}
            className={({ checked }) =>
              classNames(
                option.green.includes(router.query.section)
                  ? 'bg-green-600 text-white hover:bg-green-500'
                  : 'bg-white text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50',
                'flex items-center justify-center rounded-md px-3 py-3 text-sm font-semibold sm:flex-1',
              )
            }
          >
            {option.name}
          </RadioGroup.Option>
        ))}
      </RadioGroup>
    </fieldset>
    </div> */}
       {router.query.section ==="plans"&&<> 
        <SectionHeader
          title={props.title}
          subtitle={props.subtitle}
          strapline={props.strapline}
          className="text-center"
        />
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 lg:gap-4 items-stretch">
          {plans.map((plan, index) => (
            <>
              {(plan.id === "sby"&&!showMonth)&&
              <div className="flex flex-col items-center">
              <label className="inline-flex items-center cursor-pointer">
                <input
                  type="checkbox"
                  checked={!showMonth}
                  onChange={() => setShowMonth(!showMonth)}
                  className="sr-only"
                />
                <div className="relative">
                  <div className={`block ${!showMonth?"bg-green-200 border border-green-700":"bg-gray-200"} w-14 h-8 rounded-full`}></div>
                  <div
                    className={`absolute left-2 top-1 w-6 h-6 rounded-full transition transform ${
                      !showMonth ? 'translate-x-5 bg-green-600' : 'bg-white'
                    }`}
                  ></div>
                </div>
                <span className="ml-3 text-sm">
                  <span className="font-medium text-gray-900">Annual billing</span>
                  <span className="text-gray-500"> (Save 10%)</span>
                </span>
              </label>
              <PlanCard subtitle= {plan.subtitle}owned ={(auth.user?.planIds?.includes(plan.id) || auth.user?.planIds?.includes("life")) && auth.user.planIsActive} handleClick={appendPlan} suppliedIds={selectedPlans} id = {plan.id} period={plan.visualPeriod?plan.visualPeriod:plan.period} key = {index} title = {plan.name} perks={plan.perks} price={plan.visualPrice?plan.visualPrice:plan.price}/>
              </div>
              }
              {(plan.id === "sbm"&&showMonth)&&
              <div className="flex flex-col items-center">
              <label className="inline-flex items-center cursor-pointer">
                <input
                  type="checkbox"
                  checked={!showMonth}
                  onChange={() => setShowMonth(!showMonth)}
                  className="sr-only"
                />
                <div className="relative">
                  <div className={`block ${!showMonth?"bg-green-200 border border-green-700":"bg-gray-200"} w-14 h-8 rounded-full`}></div>
                  <div
                    className={`absolute left-1 top-1 w-6 h-6 rounded-full transition transform ${
                      !showMonth ? 'translate-x-5 bg-green-600' : 'bg-white'
                    }`}
                  ></div>
                </div>
                <span className="ml-3 text-sm">
                  <span className="font-medium text-gray-900">Annual billing</span>
                  <span className="text-gray-500"> (Save 10%)</span>
                </span>
              </label>
              <PlanCard subtitle= {plan.subtitle}owned ={(auth.user?.planIds?.includes(plan.id) || auth.user?.planIds?.includes("life")) && auth.user.planIsActive} handleClick={appendPlan} suppliedIds={selectedPlans} id = {plan.id} period={plan.visualPeriod?plan.visualPeriod:plan.period} key = {index} title = {plan.name} perks={plan.perks} price={plan.visualPrice?plan.visualPrice:plan.price}/>
              </div>}
              {(plan.id !== "sbm"&&plan.id !=="sby")&&
              <PlanCard subtitle= {plan.subtitle}owned ={(auth.user?.planIds?.includes(plan.id) || auth.user?.planIds?.includes("life")) && auth.user.planIsActive} handleClick={appendPlan} suppliedIds={selectedPlans} id = {plan.id} period={plan.visualPeriod?plan.visualPeriod:plan.period} key = {index} title = {plan.name} perks={plan.perks} price={plan.visualPrice?plan.visualPrice:plan.price}/>
              }
            </>
          ))}
        </div>
        </>}
          {/* {router.query.section === "ind"&&<>
        <SectionHeader
          title={"Individual Pricing"}
          subtitle={"We recommend purchasing one of the plans above as you get the best value; however, if there is a single product you would like, you can purchase it here!"}
          strapline={"Alternatively"}
          className="text-center mt-4"
        />
        <ul role="list" className="divide-y divide-gray-100">
          {items.map((item) => (
            <li key={item.id} className="flex items-center justify-between gap-x-6 py-5">
              <div className="min-w-0">
                <div className="flex items-start gap-x-3">
                  <p className="text-sm font-semibold leading-6 text-gray-900">{item.name}</p>
                  <p
                    className={classNames(
                      statuses["Complete"],
                      'mt-0.5 whitespace-nowrap rounded-md px-1.5 py-0.5 text-xs font-medium ring-1 ring-inset',
                    )}
                  >
                    {"Included In: " + item.plans.map(each => plans.find(plan => plan.id === each).name).join(" or ")}
                  </p>
                </div>
                <div className="mt-1 flex items-center gap-x-2 text-xs leading-5 text-gray-500">
                  <p className="whitespace-nowrap">
                    {"$" +item.price}
                  </p>
                  <svg viewBox="0 0 2 2" className="h-0.5 w-0.5 fill-current">
                    <circle r={1} cx={1} cy={1} />
                  </svg>
                  <p className="truncate">{"Billed "+item.period}</p>
                </div>
              </div>
              <div className="flex flex-none items-center gap-x-4">
                <button
                disabled={arraysShareElement(selectedPlans, item.plans) || (auth.user?.planIsActive && (auth.user?.planIds?.includes(item.id)) || (arraysShareElement(auth.user?.planIds,  item.plans) && auth.user?.planIsActive) || auth.user?.planIds?.includes("life"))}
                onClick={() =>appendItem(item.id)}
                  className={`${(selectedPlans.includes(item.id) || arraysShareElement(selectedPlans, item.plans) || selectedPlans.includes("life") ||(auth.user?.planIsActive && (auth.user?.planIds?.includes(item.id) || arraysShareElement(auth.user?.planIds,  item.plans) || auth.user?.planIds?.includes("life"))))?"bg-gray-100 text-green-700": "text-white bg-green-700 hover:bg-green-600"} w-full  py-2 px-6 rounded-md`}
                >
                  {selectedPlans.includes(item.id)?"Selected":(arraysShareElement(selectedPlans, item.plans) || arraysShareElement(selectedPlans, item.plans) || selectedPlans.includes("life"))?"Included in Selected Plan":(auth.user?.planIsActive && (auth.user?.planIds?.includes(item.id) || (auth.user?.planIsActive && arraysShareElement(auth.user?.planIds, item.plans)) || auth.user?.planIds?.includes("life")))?"Included in Current Plan":"Select"}
                </button>
              </div>
            </li>
          ))}
        </ul>
        </>} */}

        {(selectedPlans.length>0)?
        <div className="flex flex-row justify-between border-t-4 border-green-700 m-4 p-8">
        <div>
          <label htmlFor="seats" className="block text-sm font-medium leading-6 text-gray-900">
            Number of Seats
          </label>
          <div className="relative mt-2 rounded-md">
            <input
              id="seats"
              name="seats"
              type="text"
              value={quantity}
              placeholder="5"
              onChange={handleChange}
              className="block w-32 rounded-md border-0 py-4 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-green-600 text-2xl font-thin sm:leading-6"
            />
          <div className="absolute inset-y-0 right-0 flex items-center pr-3 flex flex-row">
            <div className="flex flex-col">
              <button onClick={()=>setQuantity(quantity+1)} className="hover:bg-gray-100 rounded-full">
                <ChevronUpIcon className="h-6"/>
                </button>
              <button onClick={() => setQuantity(quantity>1?quantity-1:1)} className="hover:bg-gray-100 rounded-full">
                <ChevronDownIcon className="h-6"/>
              </button>
            </div>
            <div className="group">
              <QuestionMarkCircleIcon aria-hidden="true" className="h-8 w-8 text-green-700 relative" />
              <div className="z-40 absolute left-20 top-0 w-64 p-2 bg-white text-gray-700 border border-green-700 rounded-md text-sm hidden group-hover:block">
                Each plan or product comes with a default of 5 seats. This means you and four other members within your team will have access to the product.
                Please enter how many seats you need, and we will calculate the quantity needed for you.
              </div>
            </div>
          </div>
          </div>
        </div>
        <Link to = {(arraysShareElement(auth.user.planIds, ["sby", "sbm","tp", "proc"])&&arraysShareElement(["sby", "sbm","tp", "proc"], selectedPlans))?"/settings/billing":`/purchase/${quantity}-${selectedPlans.join("-")}`}>
        <button className="w-full text-xl bg-green-700 rounded-lg text-white p-3 my-4">Purchase Selected Plans</button>
        </Link>

        </div>

        :
        <button disabled={true} className="w-full text-xl bg-gray-100 rounded-lg text-green-700 p-3 my-4">Select a Plan to Purchase</button>}

      </div>
      {selectedPlans.length>0&&
      <>
      <div className="fixed top-20 right-5 w-72 p-4 bg-white border border-green-700 rounded-lg">
      <ul className="list-none p-0 m-0">
        {selectedPlans.map((item, index) => (
          <li
            key={index}
            className="flex flex-row justify-between py-2 border-b last:border-none border-gray-200 text-xs font-bold text-green-700"
          >
            {Math.ceil(quantity/5)+" x "}
            {plans.find(element => element.id === item)?.name || items.find(element => element.id === item)?.name}
            <p className="text-gray-400">${plans.find(element => element.id === item)?.price || items.find(element => element.id === item)?.price}{((plans.find(element => element.id === item)?.period=== "per year")|| (items.find(element => element.id === item)?.period) === "per year")?"/yr":((plans.find(element => element.id === item)?.period === "per month")|| (items.find(element => element.id === item)?.period) === "per month")?"/mo":""}</p>
          </li>
        ))}
        <li
            className="flex flex-row justify-between py-2 border-t border-green-700 text-xs font-bold text-green-700"
          >
            {"Subtotal: "}

            <p className="text-gray-400">{ calculateTotals(selectedPlans).join(" + ")}</p>
          </li>
      </ul>
    </div>
      </>}
    </Section>
</>
  );
}

export default PricingSection;
