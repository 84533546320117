import React, { useState } from "react";
import {
  ChevronDownIcon,
  Squares2X2Icon,
  CogIcon,
  LockClosedIcon,
  Bars3Icon,
} from "@heroicons/react/24/solid";
import { Menu, Transition } from "@headlessui/react";
import logo from "../assets/logo.png"
import Section from "./Section";
import { Link, useRouter } from "./../util/router";
import FeatureIcon from "./FeatureIcon";
import Button from "./Button";
import { useAuth } from "./../util/auth";
import { UserGroupIcon, ArrowRightOnRectangleIcon } from "@heroicons/react/24/outline";
import {UserCircleIcon} from "@heroicons/react/24/solid";

function Navbar(props) {
  const router = useRouter()
  const auth = useAuth();

  const [mobileNavOpen, setMobileNavOpen] = useState(false);

  const classes = {
    navLink:
      "font-semibold inline-flex items-center space-x-1 h-8 px-4 group-hover:text-green-700 py-6",
    navLinkIcon:
      "opacity-50 transform transition duration-200 ease-out group-hover:rotate-180 inline-block w-4 h-4",
    dropdown: {
      base: "absolute top-19 pt-1 z-10 invisible group-hover:visible transform transition duration-800 ease-in opacity-0 group-hover:opacity-100",
      left: "right-0",
      center: "left-1/2 -translate-x-1/2",
      right: "left-0",
      normal: "w-48",
      large: "w-96",
      inner:
        "bg-white shadow-xl ring-1 ring-black ring-opacity-5 rounded-lg overflow-hidden",
      title:
        "text-xs uppercase font-semibold tracking-wider text-green-800 mb-5",
      link: "text-gray-600 hover:text-green-600 font-medium text-sm flex items-center space-x-2",
      icon: "opacity-25 inline-block w-5 h-5",
      feature:
        "p-3 rounded-xl flex items-center space-x-4 text-gray-600 font-medium text-sm",
      featureName: "font-semibold mb-1",
      featureDescription: "opacity-75",
    },
    accountDropdown: {
      base: "absolute right-0 origin-top-right mt-2 w-48 z-10",
      inner:
        "bg-white ring-1 ring-black ring-opacity-5 rounded divide-y divide-gray-100 shadow-xl rounded",
      link: "flex items-center space-x-2 rounded py-2 px-3 text-sm font-medium text-gray-600 hover:bg-gray-100 hover:text-gray-700",
      linkActive: "text-gray-700 bg-gray-100",
      linkInactive:
        "text-gray-600 hover:bg-gray-100 hover:text-gray-700 focus:outline-none focus:bg-gray-100 focus:text-gray-700",
      icon: "opacity-50 inline-block w-5 h-5",
    },
  };

  return (
    <Section bgColor={props.bgColor}>
      <div className="container py-4">
        <div className="flex justify-between">
          <Link
            to="/"
            className="inline-flex items-center space-x-2 font-bold text-lg tracking-wide text-green-600 hover:cursor-pointer"
          >
            <img className="h-12" src={logo}/>
          </Link>
          <div className="flex flex-row items-center w-full justify-end lg:justify-between ml-auto space-x-1">
            <ul className="hidden lg:flex items-center ml-auto w-full justify-between ml-20">
              <li className="relative group">
                <Link to="/all" className={`${classes.navLink}`}>
                  <span>All Packages</span>
                </Link>
              </li>
              <li className="relative group">
                <Link to="/packages/life" className={`${classes.navLink}`}>
                  <span>Million-Dollar Membership</span>
                </Link>
              </li>
              <li className="relative group">
                <Link to="/pricing/plans" className={`${classes.navLink}`}>
                  <span>Shop</span>
                </Link>
              </li>
              {/* <li className="relative group">
                <Link to="/blog/all" className={`${classes.navLink}`}>
                  <span>Blog</span>
                </Link>
              </li> */}
              <li className="relative group">
                <Link to="/about" className={`${classes.navLink}`}>
                  <span>About Us</span>
                </Link>
              </li>
              <li className="relative group">
                <Link to="/tmpl/await" className={`${classes.navLink}`}>
                  <span>Free Ebook</span>
                </Link>
              </li>
              {/* <li className="relative group">
                <Link to="/testimonials" className={`${classes.navLink}`}>
                  <span>Testimonials</span>
                </Link>
              </li> */}

              {(auth.user)&&<Menu as="div" className="relative inline-block">
              <Button
                  className="border"
                component={Menu.Button}
                variant="simple"
                size="sm"
                endIcon = {<ChevronDownIcon className="opacity-50 inline-block w-5 h-5" />
              }
              >
                <UserCircleIcon className="h-10"/>
              </Button>
              <Transition
                as={React.Fragment}
                enter="transition ease-out duration-150"
                enterFrom="transform opacity-0 scale-75"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-100"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-75"
              >
                <Menu.Items className={classes.accountDropdown.base}>
                  <div className={`${classes.accountDropdown.inner}`}>
                    <div className="p-2 space-y-1">
                      <Menu.Item>
                        <Link
                          to="/dashboard/dashboard"
                          className={`${classes.accountDropdown.link}`}
                        >
                          <Squares2X2Icon
                            className={classes.accountDropdown.icon}
                          />
                          <span>Dashboard</span>
                        </Link>
                      </Menu.Item>
                      <Menu.Item>
                        <Link
                          to="/settings/account"
                          className={`${classes.accountDropdown.link}`}
                        >
                          <CogIcon className={classes.accountDropdown.icon} />
                          <span>Settings</span>
                        </Link>
                      </Menu.Item>
                      <Menu.Item>
                        <Link
                          to="/dashboard/dashboard"
                          className={`${classes.accountDropdown.link}`}
                        >
                          <ArrowRightOnRectangleIcon
                            className={classes.accountDropdown.icon}
                          />
                          <span>Enter Application</span>
                        </Link>
                      </Menu.Item>
                    </div>
                    <div className="p-2 space-y-1">
                      <Link
                        to="#"
                        className={`${classes.accountDropdown.link}`}
                        onClick={(e) => {
                          e.preventDefault();
                          auth.signout();
                        }}
                      >
                        <LockClosedIcon
                          className={classes.accountDropdown.icon}
                        />
                        <span>Sign out</span>
                      </Link>
                    </div>
                  </div>
                </Menu.Items>
              </Transition>
          </Menu>}

              {!auth.user && (
                <li className="relative group">
                  <Link to="/auth/signin" className={`${classes.navLink}`}>
                    <span>Sign in</span>
                  </Link>
                </li>
              )}
            </ul>

            <div className="flex lg:hidden items-center justify-center">
              <Button
                variant="simple"
                size="sm"
                onClick={() => setMobileNavOpen(!mobileNavOpen)}
                endIcon={<Bars3Icon className="inline-block w-5 h-5" />}
              />
            </div>
          </div>
        </div>
        <div className={"lg:hidden" + (!mobileNavOpen ? " hidden" : "")}>
          <div className="grid grid-cols-1 sm:grid-cols-3 border rounded-xl my-5">
            
            {auth.user&&<div className="col-span-2 p-3 space-y-6">
              <div className="px-3 pt-3">
                <h4 className={`${classes.dropdown.title}`}>
                  Application Quick Links
                </h4>
              </div>
              <nav className="flex flex-col space-y-1">
                <Link
                  to="/dashboard/dashboard" onClick={() => setMobileNavOpen(false)}
                  className={`hover:text-emerald-600 hover:bg-emerald-50 ${classes.dropdown.feature}`}
                >
                  <div>
                    <FeatureIcon color="emerald">
                      <Squares2X2Icon />
                    </FeatureIcon>
                  </div>
                  <div>
                    <h5 className={`${classes.dropdown.featureName}`}>
                      Dashboard
                    </h5>
                    <p className={`${classes.dropdown.featureDescription}`}>
                      Get an overview on analytics and performance!
                    </p>
                  </div>
                </Link>
                <Link
                  to="/teams/all" onClick={() => setMobileNavOpen(false)}
                  className={`hover:text-emerald-600 hover:bg-emerald-50 ${classes.dropdown.feature}`}
                >
                  <div>
                    <FeatureIcon color="emerald">
                      <UserGroupIcon />
                    </FeatureIcon>
                  </div>
                  <div>
                    <h5 className={`${classes.dropdown.featureName}`}>
                      Team Management
                    </h5>
                    <p className={`${classes.dropdown.featureDescription}`}>
                      View nad manage your teams.
                    </p>
                  </div>
                </Link>
                <Link
                  to="/tools/virtual" onClick={() => setMobileNavOpen(false)}
                  className={`hover:text-emerald-600 hover:bg-emerald-50 ${classes.dropdown.feature}`}
                >
                  <div>
                    <FeatureIcon color="emerald">
                      <CogIcon />
                    </FeatureIcon>
                  </div>
                  <div>
                    <h5 className={`${classes.dropdown.featureName}`}>
                      Tools
                    </h5>
                    <p className={`${classes.dropdown.featureDescription}`}>
                      Send Stairs & Buckets to clients, view trainings, and sales processes!
                    </p>
                  </div>
                </Link>
              </nav>

              <nav className="flex flex-col space-y-3">
                <Link to="/help/faq" onClick={() => setMobileNavOpen(false)} className={`${classes.dropdown.link}`}>
                  Help
                </Link>
                <Link to="/settings/account" onClick={() => setMobileNavOpen(false)} className={`${classes.dropdown.link}`}>
                  Settings
                </Link>
                <Link to="/pricing/plans" onClick={() => setMobileNavOpen(false)} className={`${classes.dropdown.link}`}>
                  Shop
                </Link>
                {/* <Link to="/testimonials" onClick={() => setMobileNavOpen(false)} className={`${classes.dropdown.link}`}>
                  Testimonials
                </Link> */}

                  <Link
                    to="#"
                    className={`${classes.dropdown.link}`}
                        onClick={(e) => {
                          e.preventDefault();
                          auth.signout();
                    }}
                  >
                    Sign Out
                  </Link>
              </nav>

            </div>}
            {!auth.user && (
            <div className="p-6 space-y-6">
              <nav className="flex flex-col space-y-3">
                <Link to="/all" onClick={() => setMobileNavOpen(false)} className={`${classes.dropdown.link}`}>
                  All Packages
                </Link>
                <Link to="/packages/sb" onClick={() => setMobileNavOpen(false)} className={`${classes.dropdown.link}`}>
                  Stairs & Buckets
                </Link>
                <Link to="/packages/tp" onClick={() => setMobileNavOpen(false)} className={`${classes.dropdown.link}`}>
                  Trainings Package
                </Link>
                <Link to="/packages/proc" onClick={() => setMobileNavOpen(false)} className={`${classes.dropdown.link}`}>
                  Processes Package
                </Link>
                <Link to="/packages/life" onClick={() => setMobileNavOpen(false)} className={`${classes.dropdown.link}`}>
                  Million-Dollar Membership
                </Link>
                <Link to="/pricing/plans" onClick={() => setMobileNavOpen(false)} className={`${classes.dropdown.link}`}>
                  Shop
                </Link>
                {/* <Link to="/blog/all" onClick={() => setMobileNavOpen(false)} className={`${classes.dropdown.link}`}>
                  Blog
                </Link> */}
                <Link to="/about" onClick={() => setMobileNavOpen(false)} className={`${classes.dropdown.link}`}>
                  About Us
                </Link>
                {/* <Link to="/testimonials" onClick={() => setMobileNavOpen(false)} className={`${classes.dropdown.link}`}>
                  Testimonials
                </Link> */}
                  <Link
                    to="/auth/signin" onClick={() => setMobileNavOpen(false)}
                    className={`${classes.dropdown.link}`}
                  >
                    Sign in
                  </Link>
              </nav>
            </div>
            )}
          </div>
        </div>
      </div>
    </Section>
  );
}

export default Navbar;
